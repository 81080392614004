<template>
  <div id="customerUploadHistoryPage">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <div class="text-2xl font-semibold text-gray-900">Past Imports (CSV)</div>
        <p class="mt-1 text-sm text-gray-700">List of all customer csv uploads done in past.</p>
      </div>
      <div class="mt-0 sm:mt-0 sm:ml-16 sm:flex-none">
        <router-link class="uploadCustomerBtn" :to="{ name: 'CustomersUpload' }">
          <el-button size="small" type="primary" icon="el-icon-upload">Import Customers</el-button>
        </router-link>
      </div>
    </div>

    <!-- List -->
    <div v-if="!fetchingData && uploadList.length > 0" class="overflow-hidden mt-2 w-full border border-gray-100 sm:rounded-lg mb-4">
      <!-- Header -->
      <el-row class="py-2.5 pl-0 border-b text-gray-500 bg-gray-50 text-sm font-semibold text-gray-900 text-center">
        <el-col :span="5" class="pt-2.5"> Upload Date </el-col>
        <el-col :span="3" class="pt-2.5"> Status </el-col>
        <el-col :span="3">
          Total <br />
          Csv Rows
        </el-col>
        <el-col :span="3"> Customers <br />Created</el-col>
        <el-col :span="3">
          Customers<br />
          Updated
        </el-col>
        <el-col :span="5" class="pt-2.5"> Static List </el-col>
        <el-col :span="2" class="pt-2.5"> CSV File </el-col>
      </el-row>

      <!-- Rows -->
      <el-row class="py-2.5 pl-0 border-b text-gray-600 bg-white text-center" v-for="(upload, index) in uploadList" :key="index">
        <!-- Upload Time -->
        <el-col :span="5" class="" style="font-size: 13px">
          {{ moment(parseInt(upload.createdAt)).format('DD-MM-YYYY hh:mm A') }}
          <div class="pt-1"></div>
          By {{ upload.createdBy }}
        </el-col>

        <!-- Status -->
        <el-col :span="3" class="text-sm text-black">
          <div class="pt-2.5 align-center justify-center">
            <span v-if="upload.status == 'pending'" class="inline-flex items-center rounded-full bg-yellow-500 px-2.5 py-0.5 text-xs font-medium text-white">In Progress</span>
            <span v-else-if="upload.status == 'success'" class="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-white">Completed</span>
            <span v-else-if="upload.status == 'failed'" class="inline-flex items-center rounded-full bg-red-500 px-2.5 py-0.5 text-xs font-medium text-white">Failed</span>

            <!-- Show Failure Reason Button For Failed -->
            <div class="pl-1" v-if="upload.status == 'failed'">
              <a class="text-primary-600 hover:text-primary-600 cursor-pointer" @click="showErrorDetails(index)">
                <div class="mt-1" style="font-size: 12px">Failure Details</div>
              </a>
            </div>
          </div>
        </el-col>

        <!-- Total -->
        <el-col :span="3" class="pt-2 text-sm">
          <div v-if="upload.status == 'success'">{{ getNumberWithComma(JSON.parse(upload.data)['total']) }}</div>
          <div v-else class="pt-1">&nbsp;</div>
        </el-col>

        <!-- Insert -->
        <el-col :span="3" class="pt-2 text-sm">
          <div v-if="upload.status == 'success'">{{ getNumberWithComma(JSON.parse(upload.data)['insert']) }}</div>
          <div v-else class="pt-1">&nbsp;</div>
        </el-col>

        <!-- Update -->
        <el-col :span="3" class="pt-2 text-sm">
          <div v-if="upload.status == 'success'">{{ getNumberWithComma(JSON.parse(upload.data)['update']) }}</div>
          <template v-else>&nbsp;</template>
        </el-col>

        <!-- Static List -->
        <el-col :span="5" class="pt-2 break-all" style="font-size: 12px">
          <template v-if="upload.staticList">
            <div v-if="upload.staticList.archived" class="text-gray-450">
              {{ upload.staticList.name }}
              <br /><span style="font-size: 10px">(deleted)</span>
            </div>
            <template v-else
              ><router-link class="text-primary-600" :to="'/segment/static-list/' + upload.staticList.id" target="_blank"> {{ upload.staticList.name }} <i class="fa-solid fa-arrow-up-right"></i></router-link
            ></template>
          </template>
          <template v-else> N/A </template>
        </el-col>

        <!-- Download Button -->
        <el-col :span="2" class="text-base text-primary-600 pt-2 text-right pr-4">
          <!-- Retry failed uploads. -->
          <template v-if="$store.state.token.email == 'ravi@growlytics.in'">
            <el-tooltip v-if="upload.status == 'failed' && upload.retryAllowed" class="item cursor-pointer" effect="dark" content="Retry Upload" placement="bottom">
              <a class="text-primary-600" @click="retryUpload(index)"> <i class="fa-sharp fa-regular fa-arrow-rotate-right" v-loading.fullscreen.lock="showRetryLoader"></i></a>
            </el-tooltip>
            &nbsp;&nbsp;
          </template>

          <!-- Download CSV. -->
          <el-tooltip class="item cursor-pointer" effect="dark" content="Download CSV File" placement="bottom">
            <a class="text-primary-600" :href="upload.url" target="_blank"> <i class="fa-sharp fa-regular fa-circle-arrow-down"></i></a>
          </el-tooltip>
        </el-col>
      </el-row>

      <!-- Bottom Pagination -->
      <div class="pagination pagination-no-borders">
        <div class="totalCount">Total {{ pageOptions.total }} Uploads</div>
        <el-pagination @current-change="fetchUploadList()" :current-page.sync="pageOptions.page" :page-size="pageOptions.limit" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
      </div>
    </div>

    <!-- Loader -->
    <div class="w-full border rounded-md overflow-hidden" v-if="fetchingData" v-loading="true" style="height: 500px"></div>

    <!-- No Data -->
    <button v-if="!fetchingData && uploadList.length == 0" type="button" class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-36 text-center bg-white">
      <i class="fa-thin fa-file-csv text-gray-400 text-6xl mb-1"></i>
      <span class="mt-2 block text-sm font-medium text-gray-900">No customers imported yet.</span>
      <div class="mt-6">
        <router-link :to="{ name: 'CustomersUpload' }">
          <el-button size="small" type="primary" icon="el-icon-upload">Import Customers</el-button>
        </router-link>
      </div>
    </button>

    <!-- Failure Details Dialog -->
    <el-drawer custom-class="uploadFailureErrorDetailsDialog" title="Failure Details" direction="btt" :visible.sync="failureDetailsPopup.show">
      <div class="text-md text-red-500 text-center w-full py-3">
        {{ failureDetailsPopup.title }}
      </div>

      <div style="max-height: 60vh; overflow-y: scroll">
        <el-table v-if="failureDetailsPopup.validationErrorList" border :data="failureDetailsPopup.validationErrorList" style="width: 100%">
          <el-table-column prop="row" label="Row No" width="180"> </el-table-column>
          <el-table-column prop="errors" label="Errors"> </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="failureDetailsPopup.show = false">OK</el-button>
      </span>
    </el-drawer>
  </div>
</template>
<style lang="scss" src="./customerUploadHistory.scss"></style>

<script>
import customerUploadHistoryComponent from './customerUploadHistoryComponent';
export default customerUploadHistoryComponent;
</script>
