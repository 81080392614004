const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.getUploadHistoryList = function (params) {
  let path = `customer/csv/list`;
  return axios.post(baseUrlNew + path, params, {
    headers: buildAuthHeaders()
  });
};

exports.retryUpload = function (params) {
  let path = `customer/csv/failure-retry`;
  return axios.post(baseUrlNew + path, params, {
    headers: buildAuthHeaders()
  });
};

exports.getFailureDetails = function (params) {
  let path = `customer/csv/failure/details`;
  return axios.post(baseUrlNew + path, params, {
    headers: buildAuthHeaders()
  });
};

// exports.customerUploadTestApi = function (params) {
//   let path = `customer/csv/test`;
//   return axios.post(baseUrlNew + path, params, {
//     headers: buildAuthHeaders()
//   });
// };
