import customerUploadService from '@/services/customerUpload';
import moment from 'moment';

export default {
  components: {},
  name: 'customerUploadHistoryComponent',
  data() {
    return {
      moment: moment,
      fetchingData: false,
      fetchCronInterval: null,

      pageOptions: {
        limit: 20,
        page: 1,
        total: null
      },

      uploadList: [],
      showRetryLoader: false,

      // Failure Details Popup Variables
      failureDetailsPopup: {
        show: false,
        title: '',
        validationErrorList: null
      }
    };
  },

  methods: {
    resetPaginationAndSearch() {
      this.pageOptions.page = 1;
      this.fetchSegmentList();
    },

    async fetchUploadList(fetchInBackground) {
      if (!fetchInBackground) this.fetchingData = true;
      try {
        let params = {
          getCount: this.pageOptions.total == null ? true : false,
          pageOptions: this.pageOptions
        };
        let result = await customerUploadService.getUploadHistoryList(params);
        this.uploadList = result.data.list;

        if (result.data.count !== undefined) this.pageOptions.total = result.data.count;

        this.fetchingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch upload history! Please contact support.');
      }
    },

    async retryUpload(index) {
      try {
        this.showRetryLoader = true;
        let params = {
          uploadId: this.uploadList[index].id
        };

        await customerUploadService.retryUpload(params);

        this.uploadList[index].status = 'pending';
      } catch (err) {
        this.errorToast('Failed to retry upload! Please contact support.');
        console.error('Error in retryUpload', err);
      } finally {
        this.showRetryLoader = false;
      }
    },

    async showErrorDetails(index) {
      try {
        // Fetch Error Details
        let params = {
          uploadId: this.uploadList[index].id
        };
        let result = await customerUploadService.getFailureDetails(params);
        let details = JSON.parse(result.data.reason);

        // Parse Error Details
        this.failureDetailsPopup.title = details.error;
        if (details.error == 'Invalid CSV Input.') {
          this.failureDetailsPopup.validationErrorList = details.invalidRows;
        }

        // Show Popup
        this.failureDetailsPopup.show = true;
      } catch (err) {
        this.errorToast('Failed to fetch error details! Please contact support.');
        console.error('Error in fetchErrorDetails', err);
      }
    }
  },

  mounted() {
    // customerUploadService.customerUploadTestApi();
    this.fetchUploadList();
    this.fetchCronInterval = setInterval(() => {
      this.fetchUploadList(true);
    }, 5000);
  },

  beforeDestroy() {
    if (this.fetchCronInterval != null) {
      clearInterval(this.fetchCronInterval);
    }
  }
};
